import { dateFormatter } from "utils";
import { Divider, Image } from "@chakra-ui/react";
import { ListingType, PropertyType, TenantType } from "types";
import { ApplicationRow } from "../../ApplicationRow";
import { PrimaryButton, SecondaryButton } from "components/molecules";
import DefaultImg from 'assets/images/photoHighlights-2c716dbb164304be.png'
import ViewIcon from 'assets/images/ViewIcon.svg'
import ChatIcon from 'assets/images/ChatIcon.svg'
import { Link } from "react-router-dom";

export const RenterAcceptedApplicationRow = ({
  tenant,
  listing,
  property,
  viewTenant
}: {
  tenant: TenantType,
  listing: ListingType,
  property: PropertyType,
  viewTenant: (l: TenantType) => void,
}) => {
  const { name, age, tenantData, profilepic, _id } = tenant;
  const { moveInDate, moveOutDate, budget } = tenantData;
  return (
    <ApplicationRow 
    img={profilepic ? `${process.env.REACT_APP_BASE_URL}/${profilepic}` : DefaultImg}
    main={name}
    subMain={
      <>
        {age} 
        <Divider mx="1" display={'inline'} orientation="vertical" /> 
        {budget}$ 
        <Divider mx="1" display={'inline'} orientation="vertical" /> 
        {dateFormatter(moveInDate)} - 
        {dateFormatter(moveOutDate)}
      </>
    }
    buttons={
      <>
        <SecondaryButton mr={[0, 2]} mb={[2, 0]} onClick={() => viewTenant(tenant)} padding={4} color="black">
          <Image src={ViewIcon} mr={2} /> View Tenant
        </SecondaryButton>
        <Link to={"/app/chat/"+_id}>
          <PrimaryButton padding={4}>
            <Image src={ChatIcon} mr={2} /> Chat
          </PrimaryButton>
        </Link>
      </>
    }
    />
  )
}
