import { useContext } from "react";
import { Flex, Spacer } from "@chakra-ui/react";
import { LogoWithName } from "components/organisms";
import { StatusEnum } from "utils";
import { AllPagesContext, RenterPages, SeekerPages } from "context";
import { Heading1Base } from "components/atoms";
import { TextLargeBase } from "components/atoms";
import {Text } from "@chakra-ui/react";

export const PageZero = ({ setStatus }: { setStatus: (num: number) => void }) => {
  const { setAllPages } = useContext(AllPagesContext);
  const updateStatus = (status: number) => {
    setStatus(status);
  };

  return (
    <Flex
      height={"100vh"}
      margin={"auto"}
      minHeight={"fit-content"}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Flex width={["100%", "40%"]} flexDir={"column"} alignItems={"center"}>
        <div style={{ textAlign: "center" }}>
          <LogoWithName noName={true} size="70px" />
          <Spacer my={6} flex={0} />
          <Heading1Base>What are you looking for?</Heading1Base>
        </div>
        <Spacer my={4} flex={0} />
        <Flex width={["100%", "80%"]} flexDirection={['column', 'row']}>
          {/* Tenants Button */}
          <Flex
            flexBasis={"100%"}
            flexDirection={"column"}
            alignItems={"center"}
            margin={["10px", "0px 30px 0px 0px"]}
            justifyContent={"center"}
            style={{
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              padding: "20px",
              borderRadius: "10px",
              border: "1px solid grey",
              transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition
            }}
            onClick={() => {
              updateStatus(StatusEnum.Renter);
              setAllPages(RenterPages);
            }}
            _hover={{ bg: "gray.100", boxShadow: "lg" }} // Hover effect for Tenants button
          >
           
            <TextLargeBase style={{
              fontWeight:400
            }}>Tenants</TextLargeBase>
          </Flex>

          {/* Apartments Button */}
          <Flex
            flexBasis={"100%"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            margin={["10px", "0px"]}
            style={{
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              padding: "20px",
              borderRadius: "10px",
              border: "1px solid grey",
              transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition
            }}
            onClick={() => {
              updateStatus(StatusEnum.Tenant);
              setAllPages(SeekerPages);
            }}
            _hover={{ bg: "gray.100", boxShadow: "lg" }} // Hover effect for Apartments button
          >
            {/*<Image src={apartmentIcon} />*/}
            
            <Text  textStyle="book" fontSize="lg">Rooms</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
