import { useEffect, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { Grid, GridItem, Spacer, Text, Box, List, ListItem } from '@chakra-ui/react';
import { Heading1, InputField } from 'components/molecules';
import { getAddressString, getAddressValue } from 'utils';

export const Location = (): JSX.Element => {
  const { setFieldValue, values }: FormikValues = useFormikContext();
  const [suggestions, setSuggestions] = useState([]);

  // Fetch address suggestions from OpenStreetMap
  const fetchAddresses = async (input: string) => {
    if (input.length < 3) {
      setSuggestions([]);
      return;
    }

    const url = `https://nominatim.openstreetmap.org/search?q=${input}&countrycodes=de&format=json&addressdetails=1`;
    try {
      const response = await fetch(url, {
        headers: { 'User-Agent': 'YourAppName' }, // Replace 'YourAppName' with your application name
      });
      const data = await response.json();
      setSuggestions(
        data
          .filter((item: any) => item.address) // Ensure item contains address information
          .filter((item: any) => item.address.road) // Ensure item contains address information
          .map((item: any) => {
            return {
              label: getAddressString(item),
              value: JSON.stringify(item)
            };
          })
      );
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  useEffect(() => {
    try{
      const a = JSON.parse(values.property.location)
      console.log(a)
    }
    catch(e){
      const timeout = setTimeout(() => {
        fetchAddresses(values.property.location)
      }, 300);
  
      return () => {
        clearTimeout(timeout)
      }  
    }
  } , [values.property.location])

  const handleSelectSuggestion = (suggestion: any) => {
    setFieldValue('property.location', suggestion.value);
    setSuggestions([]);
  };

  return (
    <>
      <Heading1>Where is your apartment located?</Heading1>
      <Spacer my={2} flex={0} />
      <Grid
        templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
        gap={4}
        marginTop={0}
      >
        {/* Address Search Field */}
        <GridItem colSpan={3}>
          <Box position="relative">
            <InputField
              value={getAddressValue(values.property.location)}
              name='property.location'
              locationInput={true}
              isRequired={true}
              placeholder="Search for an address..."
            />
            {suggestions.length > 0 && (
              <Box
                position="absolute"
                bg="white"
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                maxHeight="200px"
                overflowY="auto"
                zIndex={1000}
                mt={1}
              >
                <List spacing={1}>
                  {suggestions.map((suggestion: any, index) => (
                    <ListItem
                      key={index}
                      padding={2}
                      cursor="pointer"
                      _hover={{ backgroundColor: 'gray.100' }}
                      onClick={() => handleSelectSuggestion(suggestion)}
                    >
                      {suggestion.label}
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Box>
          <Text fontSize="sm" color="gray.500" mt={2}>
            You can also enter manually if the search does not show your address correctly
          </Text>
        </GridItem>

        <GridItem colSpan={3}>
          <InputField
            name="property.title"
            type="text"
            propertyTitle={true}
            isRequired={true}
            label="Title"
            placeholder="Enter title or click generate..."
          />
        </GridItem>
      </Grid>

      <Spacer my={6} flex={0} />
      <Heading1>How much rent is your Apartment?</Heading1>
      <Grid
        templateRows={['repeat(2, 1fr)']}
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        gap={6}
        marginTop={4}
      >
        {/* Total Rent */}
        <GridItem colSpan={1}>
          <InputField
            name="property.rent"
            type="number"
            isRequired={true}
            label="Total Rent (€)"
            placeholder="300"
          />
        </GridItem>

        {/* Security Deposit */}
        <GridItem colSpan={1}>
          <InputField
            name="property.securityDeposit"
            type="number"
            isRequired={true}
            label="Security Deposit (€)"
            placeholder="500"
          />
        </GridItem>

        {/* Room Size */}
        <GridItem colSpan={1}>
          <InputField
            name="property.roomSize"
            type="number"
            isRequired={true}
            label="Room Size (sq. m)"
            placeholder="20"
          />
        </GridItem>

        {/* Roommates */}
        <GridItem colSpan={1}>
          <InputField
            name="property.roommates"
            type="number"
            isRequired={true}
            label="How many roommates are there?"
            placeholder="2"
          />
        </GridItem>
      </Grid>
    </>
  );
};
