// List of languages for multi-select
export const languages = [
  { label: "German", value: "de" },
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
  { label: "Spanish", value: "es" },
  { label: "Italian", value: "it" },
  { label: "Chinese", value: "zh" },
  { label: "Japanese", value: "ja" },
  { label: "Korean", value: "ko" },
  { label: "Hindi", value: "hi" },
  { label: "Russian", value: "ru" },
  { label: "Turkish", value: "tr" },
  { label: "Arabic", value: "ar" },
  { label: "Polish", value: "pl" },
  { label: "Portuguese", value: "pt" },
  { label: "Greek", value: "el" },
  { label: "Dutch", value: "nl" },
  { label: "Persian", value: "fa" },
  { label: "Vietnamese", value: "vi" },
  { label: "Serbian", value: "sr" },
  { label: "Croatian", value: "hr" },
  { label: "Bosnian", value: "bs" },
  { label: "Urdu", value: "ur" },
  { label: "Tamil", value: "ta" },
  { label: "Albanian", value: "sq" },
  { label: "Kurdish", value: "ku" },
  { label: "Danish", value: "da" },
  { label: "Romanian", value: "ro" },
  { label: "Hebrew", value: "he" },
  { label: "Bulgarian", value: "bg" },
  { label: "Czech", value: "cs" },
  { label: "Slovak", value: "sk" },
  { label: "Latvian", value: "lv" },
  { label: "Lithuanian", value: "lt" },
];

export const language_mappings: { [key: string]: string } = {
  "de": "German",
  "en": "English",
  "fr": "French",
  "es": "Spanish",
  "it": "Italian",
  "zh": "Chinese",
  "ja": "Japanese",
  "ko": "Korean",
  "hi": "Hindi",
  "ru": "Russian",
  "tr": "Turkish",
  "ar": "Arabic",
  "pl": "Polish",
  "pt": "Portuguese",
  "el": "Greek",
  "nl": "Dutch",
  "fa": "Persian",
  "vi": "Vietnamese",
  "sr": "Serbian",
  "hr": "Croatian",
  "bs": "Bosnian",
  "ur": "Urdu",
  "ta": "Tamil",
  "sq": "Albanian",
  "ku": "Kurdish",
  "da": "Danish",
  "ro": "Romanian",
  "he": "Hebrew",
  "bg": "Bulgarian",
  "cs": "Czech",
  "sk": "Slovak",
  "lv": "Latvian",
  "lt": "Lithuanian"
}

// List of countries for the searchable dropdown
export const countries = [
  { label: "Afghanistan", value: "af" },
  { label: "Albania", value: "al" },
  { label: "Algeria", value: "dz" },
  { label: "Andorra", value: "ad" },
  { label: "Angola", value: "ao" },
  { label: "Antigua and Barbuda", value: "ag" },
  { label: "Argentina", value: "ar" },
  { label: "Armenia", value: "am" },
  { label: "Australia", value: "au" },
  { label: "Austria", value: "at" },
  { label: "Azerbaijan", value: "az" },
  { label: "Bahamas", value: "bs" },
  { label: "Bahrain", value: "bh" },
  { label: "Bangladesh", value: "bd" },
  { label: "Barbados", value: "bb" },
  { label: "Belarus", value: "by" },
  { label: "Belgium", value: "be" },
  { label: "Belize", value: "bz" },
  { label: "Benin", value: "bj" },
  { label: "Bhutan", value: "bt" },
  { label: "Bolivia", value: "bo" },
  { label: "Bosnia and Herzegovina", value: "ba" },
  { label: "Botswana", value: "bw" },
  { label: "Brazil", value: "br" },
  { label: "Brunei", value: "bn" },
  { label: "Bulgaria", value: "bg" },
  { label: "Burkina Faso", value: "bf" },
  { label: "Burundi", value: "bi" },
  { label: "Cambodia", value: "kh" },
  { label: "Cameroon", value: "cm" },
  { label: "Canada", value: "ca" },
  { label: "Cape Verde", value: "cv" },
  { label: "Central African Republic", value: "cf" },
  { label: "Chad", value: "td" },
  { label: "Chile", value: "cl" },
  { label: "China", value: "cn" },
  { label: "Colombia", value: "co" },
  { label: "Comoros", value: "km" },
  { label: "Congo (Brazzaville)", value: "cg" },
  { label: "Congo (Kinshasa)", value: "cd" },
  { label: "Costa Rica", value: "cr" },
  { label: "Croatia", value: "hr" },
  { label: "Cuba", value: "cu" },
  { label: "Cyprus", value: "cy" },
  { label: "Czech Republic", value: "cz" },
  { label: "Denmark", value: "dk" },
  { label: "Djibouti", value: "dj" },
  { label: "Dominica", value: "dm" },
  { label: "Dominican Republic", value: "do" },
  { label: "Ecuador", value: "ec" },
  { label: "Egypt", value: "eg" },
  { label: "El Salvador", value: "sv" },
  { label: "Equatorial Guinea", value: "gq" },
  { label: "Eritrea", value: "er" },
  { label: "Estonia", value: "ee" },
  { label: "Ethiopia", value: "et" },
  { label: "Fiji", value: "fj" },
  { label: "Finland", value: "fi" },
  { label: "France", value: "fr" },
  { label: "Gabon", value: "ga" },
  { label: "Gambia", value: "gm" },
  { label: "Georgia", value: "ge" },
  { label: "Germany", value: "de" },
  { label: "Ghana", value: "gh" },
  { label: "Greece", value: "gr" },
  { label: "Grenada", value: "gd" },
  { label: "Guatemala", value: "gt" },
  { label: "Guinea", value: "gn" },
  { label: "Guinea-Bissau", value: "gw" },
  { label: "Guyana", value: "gy" },
  { label: "Haiti", value: "ht" },
  { label: "Honduras", value: "hn" },
  { label: "Hungary", value: "hu" },
  { label: "Iceland", value: "is" },
  { label: "India", value: "in" },
  { label: "Indonesia", value: "id" },
  { label: "Iran", value: "ir" },
  { label: "Iraq", value: "iq" },
  { label: "Ireland", value: "ie" },
  { label: "Israel", value: "il" },
  { label: "Italy", value: "it" },
  { label: "Jamaica", value: "jm" },
  { label: "Japan", value: "jp" },
  { label: "Jordan", value: "jo" },
  { label: "Kazakhstan", value: "kz" },
  { label: "Kenya", value: "ke" },
  { label: "Kiribati", value: "ki" },
  { label: "Korea (North)", value: "kp" },
  { label: "Korea (South)", value: "kr" },
  { label: "Kuwait", value: "kw" },
  { label: "Kyrgyzstan", value: "kg" },
  { label: "Laos", value: "la" },
  { label: "Latvia", value: "lv" },
  { label: "Lebanon", value: "lb" },
  { label: "Lesotho", value: "ls" },
  { label: "Liberia", value: "lr" },
  { label: "Libya", value: "ly" },
  { label: "Liechtenstein", value: "li" },
  { label: "Lithuania", value: "lt" },
  { label: "Luxembourg", value: "lu" },
  { label: "Macedonia", value: "mk" },
  { label: "Madagascar", value: "mg" },
  { label: "Malawi", value: "mw" },
  { label: "Malaysia", value: "my" },
  { label: "Maldives", value: "mv" },
  { label: "Mali", value: "ml" },
  { label: "Malta", value: "mt" },
  { label: "Marshall Islands", value: "mh" },
  { label: "Mauritania", value: "mr" },
  { label: "Mauritius", value: "mu" },
  { label: "Mexico", value: "mx" },
  { label: "Micronesia", value: "fm" },
  { label: "Moldova", value: "md" },
  { label: "Monaco", value: "mc" },
  { label: "Mongolia", value: "mn" },
  { label: "Montenegro", value: "me" },
  { label: "Morocco", value: "ma" },
  { label: "Mozambique", value: "mz" },
  { label: "Myanmar (Burma)", value: "mm" },
  { label: "Namibia", value: "na" },
  { label: "Nauru", value: "nr" },
  { label: "Nepal", value: "np" },
  { label: "Netherlands", value: "nl" },
  { label: "New Zealand", value: "nz" },
  { label: "Nicaragua", value: "ni" },
  { label: "Niger", value: "ne" },
  { label: "Nigeria", value: "ng" },
  { label: "Norway", value: "no" },
  { label: "Oman", value: "om" },
  { label: "Pakistan", value: "pk" },
  { label: "Palau", value: "pw" },
  { label: "Panama", value: "pa" },
  { label: "Papua New Guinea", value: "pg" },
  { label: "Paraguay", value: "py" },
  { label: "Peru", value: "pe" },
  { label: "Philippines", value: "ph" },
  { label: "Poland", value: "pl" },
  { label: "Portugal", value: "pt" },
  {
    "label": "Puerto Rico",
    "value": "pr"
  },
  {
    "label": "Qatar",
    "value": "qa"
  },
  {
    "label": "Réunion",
    "value": "re"
  },
  {
    "label": "Romania",
    "value": "ro"
  },
  {
    "label": "Russia",
    "value": "ru"
  },
  {
    "label": "Rwanda",
    "value": "rw"
  },
  {
    "label": "Saudi Arabia",
    "value": "sa"
  },
  {
    "label": "Solomon Islands",
    "value": "sb"
  },
  {
    "label": "Seychelles",
    "value": "sc"
  },
  {
    "label": "Sudan",
    "value": "sd"
  },
  {
    "label": "Sweden",
    "value": "se"
  },
  {
    "label": "Singapore",
    "value": "sg"
  },
  {
    "label": "Saint Helena, Ascension and Tristan da Cunha",
    "value": "sh"
  },
  {
    "label": "Slovenia",
    "value": "si"
  },
  {
    "label": "Svalbard and Jan Mayen",
    "value": "sj"
  },
  {
    "label": "Slovakia",
    "value": "sk"
  },
  {
    "label": "Sierra Leone",
    "value": "sl"
  },
  {
    "label": "San Marino",
    "value": "sm"
  },
  {
    "label": "Senegal",
    "value": "sn"
  },
  {
    "label": "Somalia",
    "value": "so"
  },
  {
    "label": "Suriname",
    "value": "sr"
  },
  {
    "label": "South Sudan",
    "value": "ss"
  },
  {
    "label": "Sao Tome and Principe",
    "value": "st"
  },
  {
    "label": "Sint Maarten",
    "value": "sx"
  },
  {
    "label": "Syria",
    "value": "sy"
  },
  {
    "label": "Eswatini",
    "value": "sz"
  },
  {
    "label": "Turks and Caicos Islands",
    "value": "tc"
  },
  {
    "label": "French Southern and Antarctic Lands",
    "value": "tf"
  },
  {
    "label": "Togo",
    "value": "tg"
  },
  {
    "label": "Thailand",
    "value": "th"
  },
  {
    "label": "Tajikistan",
    "value": "tj"
  },
  {
    "label": "Tokelau",
    "value": "tk"
  },
  {
    "label": "Timor-Leste",
    "value": "tl"
  },
  {
    "label": "Turkmenistan",
    "value": "tm"
  },
  {
    "label": "Tunisia",
    "value": "tn"
  },
  {
    "label": "Tonga",
    "value": "to"
  },
  {
    "label": "Turkey",
    "value": "tr"
  },
  {
    "label": "Trinidad and Tobago",
    "value": "tt"
  },
  {
    "label": "Tuvalu",
    "value": "tv"
  },
  {
    "label": "Taiwan",
    "value": "tw"
  },
  {
    "label": "Tanzania",
    "value": "tz"
  },
  {
    "label": "Ukraine",
    "value": "ua"
  },
  {
    "label": "Uganda",
    "value": "ug"
  },
  {
    "label": "United States Minor Outlying Islands",
    "value": "um"
  },
  {
    "label": "United States",
    "value": "us"
  },
  {
    "label": "Uruguay",
    "value": "uy"
  },
  {
    "label": "Uzbekistan",
    "value": "uz"
  },
  {
    "label": "Vatican City",
    "value": "va"
  },
  {
    "label": "Saint Vincent and the Grenadines",
    "value": "vc"
  },
  {
    "label": "Venezuela",
    "value": "ve"
  },
  {
    "label": "British Virgin Islands",
    "value": "vg"
  },
  {
    "label": "United States Virgin Islands",
    "value": "vi"
  },
  {
    "label": "Vietnam",
    "value": "vn"
  },
  {
    "label": "Vanuatu",
    "value": "vu"
  },
  {
    "label": "Wallis and Futuna",
    "value": "wf"
  },
  {
    "label": "Samoa",
    "value": "ws"
  },
  {
    "label": "Yemen",
    "value": "ye"
  },
  {
    "label": "Mayotte",
    "value": "yt"
  },
  {
    "label": "South Africa",
    "value": "za"
  },
  {
    "label": "Zambia",
    "value": "zm"
  },
  {
    "label": "Zimbabwe",
    "value": "zw"
  }
];

export const countries_map: { [key: string]: string } = {
  "af": "Afghanistan",
  "al": "Albania",
  "dz": "Algeria",
  "ad": "Andorra",
  "ao": "Angola",
  "ag": "Antigua and Barbuda",
  "ar": "Argentina",
  "am": "Armenia",
  "au": "Australia",
  "at": "Austria",
  "az": "Azerbaijan",
  "bs": "Bahamas",
  "bh": "Bahrain",
  "bd": "Bangladesh",
  "bb": "Barbados",
  "by": "Belarus",
  "be": "Belgium",
  "bz": "Belize",
  "bj": "Benin",
  "bt": "Bhutan",
  "bo": "Bolivia",
  "ba": "Bosnia and Herzegovina",
  "bw": "Botswana",
  "br": "Brazil",
  "bn": "Brunei",
  "bg": "Bulgaria",
  "bf": "Burkina Faso",
  "bi": "Burundi",
  "kh": "Cambodia",
  "cm": "Cameroon",
  "ca": "Canada",
  "cv": "Cape Verde",
  "cf": "Central African Republic",
  "td": "Chad",
  "cl": "Chile",
  "cn": "China",
  "co": "Colombia",
  "km": "Comoros",
  "cg": "Congo (Brazzaville)",
  "cd": "Congo (Kinshasa)",
  "cr": "Costa Rica",
  "hr": "Croatia",
  "cu": "Cuba",
  "cy": "Cyprus",
  "cz": "Czech Republic",
  "dk": "Denmark",
  "dj": "Djibouti",
  "dm": "Dominica",
  "do": "Dominican Republic",
  "ec": "Ecuador",
  "eg": "Egypt",
  "sv": "El Salvador",
  "gq": "Equatorial Guinea",
  "er": "Eritrea",
  "ee": "Estonia",
  "et": "Ethiopia",
  "fj": "Fiji",
  "fi": "Finland",
  "fr": "France",
  "ga": "Gabon",
  "gm": "Gambia",
  "ge": "Georgia",
  "de": "Germany",
  "gh": "Ghana",
  "gr": "Greece",
  "gd": "Grenada",
  "gt": "Guatemala",
  "gn": "Guinea",
  "gw": "Guinea-Bissau",
  "gy": "Guyana",
  "ht": "Haiti",
  "hn": "Honduras",
  "hu": "Hungary",
  "is": "Iceland",
  "in": "India",
  "id": "Indonesia",
  "ir": "Iran",
  "iq": "Iraq",
  "ie": "Ireland",
  "il": "Israel",
  "it": "Italy",
  "jm": "Jamaica",
  "jp": "Japan",
  "jo": "Jordan",
  "kz": "Kazakhstan",
  "ke": "Kenya",
  "ki": "Kiribati",
  "kp": "Korea (North)",
  "kr": "Korea (South)",
  "kw": "Kuwait",
  "kg": "Kyrgyzstan",
  "la": "Laos",
  "lv": "Latvia",
  "lb": "Lebanon",
  "ls": "Lesotho",
  "lr": "Liberia",
  "ly": "Libya",
  "li": "Liechtenstein",
  "lt": "Lithuania",
  "lu": "Luxembourg",
  "mk": "Macedonia",
  "mg": "Madagascar",
  "mw": "Malawi",
  "my": "Malaysia",
  "mv": "Maldives",
  "ml": "Mali",
  "mt": "Malta",
  "mh": "Marshall Islands",
  "mr": "Mauritania",
  "mu": "Mauritius",
  "mx": "Mexico",
  "fm": "Micronesia",
  "md": "Moldova",
  "mc": "Monaco",
  "mn": "Mongolia",
  "me": "Montenegro",
  "ma": "Morocco",
  "mz": "Mozambique",
  "mm": "Myanmar (Burma)",
  "na": "Namibia",
  "nr": "Nauru",
  "np": "Nepal",
  "nl": "Netherlands",
  "nz": "New Zealand",
  "ni": "Nicaragua",
  "ne": "Niger",
  "ng": "Nigeria",
  "no": "Norway",
  "om": "Oman",
  "pk": "Pakistan",
  "pw": "Palau",
  "pa": "Panama",
  "pg": "Papua New Guinea",
  "py": "Paraguay",
  "pe": "Peru",
  "ph": "Philippines",
  "pl": "Poland",
  "pt": "Portugal",
  "pr": "Puerto Rico",
  "qa": "Qatar",
  "re": "Réunion",
  "ro": "Romania",
  "ru": "Russia",
  "rw": "Rwanda",
  "sa": "Saudi Arabia",
  "sb": "Solomon Islands",
  "sc": "Seychelles",
  "sd": "Sudan",
  "se": "Sweden",
  "sg": "Singapore",
  "sh": "Saint Helena, Ascension and Tristan da Cunha",
  "si": "Slovenia",
  "sj": "Svalbard and Jan Mayen",
  "sk": "Slovakia",
  "sl": "Sierra Leone",
  "sm": "San Marino",
  "sn": "Senegal",
  "so": "Somalia",
  "sr": "Suriname",
  "ss": "South Sudan",
  "st": "Sao Tome and Principe",
  "sx": "Sint Maarten",
  "sy": "Syria",
  "sz": "Eswatini",
  "tc": "Turks and Caicos Islands",
  "tf": "French Southern and Antarctic Lands",
  "tg": "Togo",
  "th": "Thailand",
  "tj": "Tajikistan",
  "tk": "Tokelau",
  "tl": "Timor-Leste",
  "tm": "Turkmenistan",
  "tn": "Tunisia",
  "to": "Tonga",
  "tr": "Turkey",
  "tt": "Trinidad and Tobago",
  "tv": "Tuvalu",
  "tw": "Taiwan",
  "tz": "Tanzania",
  "ua": "Ukraine",
  "ug": "Uganda",
  "um": "United States Minor Outlying Islands",
  "us": "United States",
  "uy": "Uruguay",
  "uz": "Uzbekistan",
  "va": "Vatican City",
  "vc": "Saint Vincent and the Grenadines",
  "ve": "Venezuela",
  "vg": "British Virgin Islands",
  "vi": "United States Virgin Islands",
  "vn": "Vietnam",
  "vu": "Vanuatu",
  "wf": "Wallis and Futuna",
  "ws": "Samoa",
  "ye": "Yemen",
  "yt": "Mayotte",
  "za": "South Africa",
  "zm": "Zambia",
  "zw": "Zimbabwe"
}