import { useUpdateUserStatus, useUser } from "lib";
import { StatusEnum } from "utils";
import { ApartmentPosts } from "../ApartmentPost";
import { TenantPosts } from "../TenantPost";
import { PrimaryButton } from "components/molecules";
import { PageZero } from "components/organisms/Signup";
import { useState } from "react";

export const DashboardBody = () => {
  let { data } = useUser();

  const updateUserStatusMutation = useUpdateUserStatus()

  const updateStatus = (status: number) => {
    updateUserStatusMutation.mutate(status)
  }

  // const mu
  if (data?.status === StatusEnum.Renter){
    return(
      <TenantPosts />
    )
  }
  else if (data?.status === StatusEnum.Tenant){
    return(
      <ApartmentPosts />
    )
  }
  else{
    return(
      <PageZero setStatus={updateStatus} />
    )
  }
}