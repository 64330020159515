import { useContext, useEffect, useRef, useState } from "react";
import { Flex, Input, Button, Text } from "@chakra-ui/react";
import { SubHeader, ReviewButtons, ApartmentPost } from "components/organisms";
import { Spinner } from "components/atoms";
import { StatusEnum } from "utils";
import { useGetListings, useUser } from "lib";
import { useSwipeListing } from "lib/mutations/useSwipeListing";
import { DashboardListing } from "context";
import { useNavigate } from "react-router-dom";

export const ApartmentPosts = () => {
  const { listingId: page, setListingId: setPage } = useContext(DashboardListing);

  let { isFetching, isError, data: roomData } = useGetListings(page);
  let { data: user } = useUser();
  const navigate = useNavigate();

  let { shortlistMutation, acceptMutation, rejectMutation } = useSwipeListing();

  useEffect(() => postRef?.current?.focus(), []);

  const moveItem = (animation: "animate__zoomOutUp" | "animate__fadeOutRight" | "animate__fadeOutLeft") => {
    if (postRef.current) {
      setTimeout(() => next(), 200);
      postRef.current.classList.remove('animate__animated', 'animate__zoomIn');
      postRef.current.classList.add('animate__animated', animation);
    }
  };

  const shortlistProperty = (listingId: string) => {
    shortlistMutation.mutate(listingId, {
      onSuccess: () => moveItem("animate__zoomOutUp"),
    });
  };
  const acceptProperty = (propertyId: string) => {
    acceptMutation.mutate(propertyId, {
      onSuccess: () => moveItem("animate__fadeOutRight"),
    });
  };
  const rejectProperty = (listingId: string) => {
    rejectMutation.mutate(listingId, {
      onSuccess: () => moveItem("animate__fadeOutLeft"),
    });
  };

  const nextItem = (dir: "accept" | "reject" | "shortlist") => {
    if (user) {
      if (roomData) {
        if (dir === "shortlist") {
          shortlistProperty(roomData._id);
        }
        if (dir === "accept") {
          acceptProperty(roomData._id);
        }
        if (dir === "reject") {
          rejectProperty(roomData._id);
        }
      }
    } else {
      navigate('/login');
    }
  };

  const next = () => {
    if (roomData) {
      setPage(roomData._id);
    }
  };

  const postRef = useRef<HTMLDivElement | null>(null);
  console.log(isFetching)
  return (
    <>
      {isFetching ? (
        <Flex alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <Spinner />
        </Flex>
      ) : (
        <></>
      )}
      {!isFetching && !isError ? (
        <>
          {
          // rooms.map((listing, i) =>
            roomData ? (
              <ApartmentPost ref={postRef} listing={roomData} />
            ) : (
              <Flex
                direction="column"
                height="100%"
                width={["100vw", "75vw"]}
                alignItems={"center"}
                justifyContent={"center"}
                margin={"auto"}
                textAlign="center"
              >
                <Text fontSize="3xl" fontWeight="bold">
                  More listings soon!
                </Text>
                <Text fontSize="lg" mt={2}>
                  Be the first one to get notified about newer listings.
                </Text>
                <Flex mt={4} direction="row" alignItems="center">
                  <Input
                    type="email"
                    placeholder="Enter your email"
                    size="lg"
                    width="300px"
                    mr={2}
                  />
                  <Button colorScheme="blue" size="lg">
                    Submit
                  </Button>
                </Flex>
              </Flex>
            // )
          )}
          {<ReviewButtons onClick={(a) => { nextItem(a); }} />}
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};
