import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "lib";
import { useNotifications } from "components/organisms";

export const useSwipeListing = () => {
  const qC = useQueryClient();

  const shortlistMutation = useMutation({
    mutationKey: ['shortlistListing'],
    mutationFn: (listingId: string) => {
      return api.post('/listing/shortlist', {body: JSON.stringify({listingId})}, true, 'json');
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to shortlist property',
        message: error.message ? error.message : 'Try again',
      });
    },
    onSuccess: (data) => {
      if (data.msg){
        useNotifications.getState().addNotification({
          type: 'info',
          title: 'Failed to shortlist property',
          message: data.msg
        });  
      }
      else{
        qC.invalidateQueries({queryKey: ['getShortlistProperties']})
      }
    }
  })

  const acceptMutation = useMutation({
    mutationKey: ['acceptListing'],
    mutationFn: (listingId: string) => {
      return api.post('/listing/accept', {body: JSON.stringify({listingId})}, true, 'json');
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to swipe right property',
        message: error.message ? error.message : 'Try again',
      });
    },
    onSuccess: (data) => {
      if (data.msg){
        useNotifications.getState().addNotification({
          type: 'info',
          title: 'Info',
          message: data.msg
        });  
      }
      else{
        qC.invalidateQueries({queryKey: ['getPendingListings']})
        qC.invalidateQueries({queryKey: ['getMatchedListings']})
      }
    }
  })

  const rejectMutation = useMutation({
    mutationKey: ['rejectListing'],
    mutationFn: (listingId: string) => {
      return api.post('/listing/reject', {body: JSON.stringify({listingId})}, true, 'json');
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to swipe left property',
        message: error.message ? error.message : 'Try again',
      });
    },
    onSuccess: (data) => {
      if (data.msg){
        useNotifications.getState().addNotification({
          type: 'info',
          title: 'Info',
          message: data.msg
        });  
      }
      else{
        qC.invalidateQueries({queryKey: ['getPendingListings']})
        qC.invalidateQueries({queryKey: ['getMatchedListings']})
      }
    }
  })
  return { shortlistMutation, acceptMutation, rejectMutation };
}