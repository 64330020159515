import { configureAuth } from 'react-query-auth';
import { Navigate, useLocation } from 'react-router-dom';

import { useNotifications } from 'components/organisms';
import { SignupFormBaseValues, AuthResponse, IObjectKeys, LoginInput, User } from 'types';
import { api } from 'lib';
import { StatusEnum } from 'utils';
import { socket } from 'app/socket';

// TODO: REFACTOR STUFF

// api call definitions for auth (types, schemas, requests):
// these are not part of features as this is a module shared across features

const getUser = async (): Promise<User> => {
  if (!!localStorage.getItem('token')){
    const response = await api.get('/users/me', {}, true);
    socket.emit('new user', response.data._id)
    return response.data;
  }
  else{
    throw new Error('Session expired. Please login to continue.');
  }
};

const logout = (): Promise<void> => {
  return new Promise((r) => {localStorage.removeItem('token');r()})
};

const loginWithEmailAndPassword = (data: LoginInput): Promise<AuthResponse> => {
  return api.post('/users/login', {body: JSON.stringify(data)}, false, 'json');
};

const file_keys = ['profilepic', 'roomPhotos', 'photoHighlights'];

export const getFormData = (data: IObjectKeys) => {
  let form_data = new FormData();
  for (var key in data) {
    let val: File | FileList | File[] | string | number | null | Array<any> = data[key];
    if (val !== null){
      // for images etc
      if (file_keys.includes(key)){
        if (val instanceof File){
          form_data.append(key, val, val.name);
        }
        if (val instanceof FileList){
          [...Array(val.length)].forEach((_, i: number) => {
            if (val instanceof FileList){
              form_data.append(key, val[i], val[i].name);  
            }
          })
        }
        if (Array.isArray(val)){
          // if (Array.isArray(val)){
            val.forEach((v, i) => {
              if (v instanceof File){
                form_data.append(key, v);
              }
            });
          // }
        }
      }
      //for data that is in array
      else if (Array.isArray(val)){
        // if (Array.isArray(val)){
          val.forEach((v, i) => {
            if (v.pfp){
              if (v.pfp instanceof File){
                form_data.append(`roommateDetails-pfp`, v.pfp, `${v.pfp.name}-${i}`);
              }
              delete v.pfp;
            }
          });
          form_data.append(key, JSON.stringify({[key]: val}));
        // }
      }
      //everything else
      else{
        form_data.append(key, data[key] as string);
      }  
    }
  }
  return form_data;
}

const registerWithEmailAndPassword = (
  data: any,
): Promise<AuthResponse> => {
  let endpoint = '/users/register';
  if (data.status === StatusEnum.Tenant){
    endpoint = '/users/register-tenant';
    delete data["moveIn"];
    delete data["moveOut"];
  }
  let form_data = getFormData(data);
  return api.post(endpoint, {body: form_data}, false, '');
};

const authConfig = {
  userFn: getUser,
  loginFn: async (data: LoginInput) => {
    const response = await loginWithEmailAndPassword(data);
    localStorage.setItem('token', response.token);
    return response.data;
  },
  registerFn: async (data: SignupFormBaseValues) => {
    const response = await registerWithEmailAndPassword(data);
    localStorage.setItem('token', response.token);
    return response.data;
  },
  logoutFn: logout,
};

export const { useUser, useLogin, useLogout, useRegister, AuthLoader } =
  configureAuth(authConfig);

export const ProtectedRoute = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const {data, status, error} = useUser({
    retry: 0
  });
  const location = useLocation();
  
  if (error){
    useNotifications.getState().addNotification({
      type: 'error',
      title: 'Error',
      message: 'Session expired. Please login again.',
    });
  }

  if (status === 'pending') {
    return (
    <AuthLoader renderLoading={() => <div>Render loadng??</div>}>
      <div>Loading...</div>
    </AuthLoader>)
  }
  else if (!data){
    return (
      <Navigate
        to={`/login?redirectTo=${encodeURIComponent(location.pathname)}`}
        replace
      />
    );
  }

  return <>{children}</>;
};