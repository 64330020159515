import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TenantType } from "types";
import { api } from "lib";
import { useNotifications } from "components/organisms";
import { StatusEnum } from "utils";

export const useUpdateUserStatus = () => {
  const qC = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['updateStatus'],
    mutationFn: (status: StatusEnum) => {
      return api.patch('/users/update_status', {body: JSON.stringify({status})}, true, 'json');
    },
    onSuccess: (res: any, updatedTenant) => {
      qC.invalidateQueries({queryKey: ['authenticated-user']})
      useNotifications.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: `Fill your profile details & get started!`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to edit profile',
        message: error.message ? error.message : 'Try again',
      });
    }
  })
  return mutation;
}