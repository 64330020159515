import { FormLabel, Grid, GridItem, Select, Spacer } from '@chakra-ui/react';
import { FormikValues, useFormikContext } from 'formik';

import { ImageInputField, Heading1, RadioField, InputField, SearchSelectField } from 'components/molecules';
import { countries, languages } from 'utils/data';


// TODO: into a common components
export const ProfileDetails = (): JSX.Element => {
  let { values, setFieldValue }: FormikValues = useFormikContext();

  const fileChange = (e: any) => {
    if (e.target.files.length > 0){
      setFieldValue('user.profilepic', e.target.files[0]);
    }
  }

  return(
    <>
      <Heading1>Tell Us About Yourself</Heading1>
      <Spacer flex={0} my={3} />
      <Grid 
        templateRows='repeat(1, 1fr)'
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        gap={4}
      >
        {values.property.roommateDetails.length > 0 ? 
        <>
          <FormLabel htmlFor="roommate-copy" textStyle="medium">I am..</FormLabel>
          <Select
            name="user.language"
            placeholder="Select Roomate"
            onChange={(e: any) => {
              console.log(e);
              const roomate = values.property.roommateDetails[parseInt(e.target.value)];
              console.log(roomate, values.property);
              setFieldValue('user.name', roomate.name)
              if (roomate.pfp){
                setFieldValue(`user.profilepic`, roomate.pfp);
              }
              if (roomate.age){
                setFieldValue(`user.age`, roomate.age);
              }
              if (roomate.language){
                setFieldValue(`user.language`, roomate.language);
              }
              if (roomate.gender){
                setFieldValue(`user.gender`, roomate.gender);
              }
            }}
          >

            {values.property.roommateDetails.map((r:any, i:any) => (
              <option key={i} value={i}>
                {r.name}
              </option>
            ))}

          </Select>
        </>

        : <></>}
        <GridItem colSpan={[1,2]} rowSpan={1} >
          <ImageInputField 
          value={values.user?.profilepic}
          fileChange={fileChange}
          name='user.profilepic'
          />
        </GridItem>
        <GridItem colSpan={1} height={'fit-content'}>
          <InputField 
          name='user.name'
          value={values.user?.name}
          isRequired={true}
          label='Full Name'
          placeholder='Jonas'
          />
        </GridItem>
        <GridItem colSpan={1} height={'fit-content'}>
          <InputField 
          name='user.age'
          value={values.user?.age}
          isRequired={true}
          label='Age'
          type="number"
          placeholder='25'
          />
        </GridItem>
        <GridItem colSpan={1}>
          <SearchSelectField 
            isRequired={true}
            label="I speak"
            name="user.language"
            options={languages}
            isMulti={true}
            noOptionsMessage={() => ''}
            onInputChange={(a:string) => {}}
            placeholder="Enter languages you speak..."
            value={
              languages.filter((lang: any) => values.user.language?.includes(lang.value))
            }
            onChange={(selected:any) => {
              const selectedValues = selected.map((option:any) => option.value);
              console.log(selectedValues, selected)
              setFieldValue(`user.language`, selectedValues.join(','));
            }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <SearchSelectField 
            isRequired={true}
            label="Current Location"
            name="user.location"
            options={countries}
            isMulti={false}
            noOptionsMessage={() => ''}
            onInputChange={(a:string) => {}}
            placeholder="Enter your current country"
            value={countries.find((c) => c.value === values.user.location)}
            onChange={(selected:any) => {
              console.log(selected)
              setFieldValue(`user.location`, selected.value);
            }}
          />

        </GridItem>
        <GridItem colSpan={1}>
          <RadioField 
            isRequired={true}
            label='Gender'
            options={{1: 'Male', 2: 'Female', 3: 'Other'}}
            name='user.gender'
            value={values.user?.gender}
            />
        </GridItem>
      </Grid>
    </>
  )
}