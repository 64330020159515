import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { FormLabel } from "components/atoms";
import { useField } from "formik";

export const SearchSelectField = ({
  label,
  isRequired = false,
  ...props
  // name,
  // isDisabled = false,
  // options,
  // onInputChange,
  // onChange,
  // noOptionsMessage,
  // placeholder,
  // value
} : {
  name: string;
  onInputChange: (inputValue: string) => void
  onChange : (opt: null|{ value: string; label: string }) => void
  isRequired: boolean;
  label?: string;
  isDisabled?: boolean;
  options: { value: string; label: string }[]
  noOptionsMessage: () => string
  placeholder?: string
  isMulti?:boolean
  value: any
}) => {
  const [
    field,
    meta
  ] = useField({ name: props.name });
  console.log(field, meta)
  return(
    <FormControl isInvalid={!!meta.error && !!meta.touched} isRequired={isRequired}>
      { label ? <FormLabel textStyle="medium" htmlFor={field.name}>{label}</FormLabel>: <></> }
      <Select
      chakraStyles={{
        container: (provided) => ({
          ...provided,
          width: "100%",
        }),
      }}
      isClearable
      isSearchable
      id={field.name}
      isRequired={isRequired}
      {...props}
    />
    {(meta.error && meta.touched) ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}

    </FormControl>
  );
}