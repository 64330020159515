import * as React from 'react';
import { Flex, Hide } from '@chakra-ui/react';
import loginImage from 'assets/images/login-image.png';
import linePattern from 'assets/images/line-pattern.svg';

type LayoutVariant = 'small' | 'regular' | 'large';

interface LayoutProps {
  children: React.ReactNode;
  variant?: LayoutVariant;
}

export const AuthLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Flex height={'fit-content'} overflow={"hidden"} minHeight={'100vh'}>
      <Flex flex={1} justifyContent={'center'} alignItems={'center'}>
        {children}
      </Flex>
      <Hide breakpoint="(max-width: 971px)">
        <Flex flex={1} overflow={'none'} bg="green.500" justifyContent="center" alignItems="center" position="relative">
          {/* Line pattern image */}
          <img
            src={linePattern}
            style={{
              position: 'absolute',
              top: 0,
              right: -100,
              width: '400px',
              height: '400px',
              zIndex: 0,
            }}
          />
          {/* Main login image */}
          <img
            src={loginImage}
            style={{
              width: '90%',
              height: '90%',
              objectFit: 'contain',
              zIndex: 1,
            }}
          />

<img
            src={linePattern}
            style={{
              position: 'absolute',
              bottom: -100,
              left: -50,
              width: '400px',
              height: '400px',
              zIndex: 0,
            }}
          />
        </Flex>
      </Hide>
    </Flex>
  );
};
