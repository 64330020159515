import { Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation } from 'react-router-dom';

import { Spinner } from 'components/atoms';
import { Layout } from 'components/organisms';
import { useMutationState } from '@tanstack/react-query';
import { LoadingModal } from 'components/organisms';
import { Header } from 'components/organisms';
import { DashboardListing } from 'context';

export const AppRoot = (props: {children?: React.ReactNode}) => {
  const location = useLocation();
  
  let s = useMutationState({
    filters: { mutationKey: ['createProperty'] },
    select: (mutation) => mutation.state.status
  });
  let status = s[s.length-1];

  const [listingId, setListingId] = useState<string>('');

  const value = {listingId, setListingId};

  return (
    <Layout>
      <Suspense
        fallback={
          <div className="flex size-full items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <ErrorBoundary
          key={location.pathname}
          fallback={<div>Something went wrong!</div>}
        >
          <Header />
          <DashboardListing.Provider value={value}>
            <Outlet />
          </DashboardListing.Provider>

          {status === 'pending' ? <LoadingModal /> : <></>}
        </ErrorBoundary>
      </Suspense>
    </Layout>
  );
};