import { Swiper as SwiperLib, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Image, Grid, Hide } from "@chakra-ui/react";

type SwiperProps = {
  photoUrls: Array<any>,
  height: string,
  border?: string,
  width?: string
}

export const Swiper = ({photoUrls, height, width='100%', border = '1px dashed rgba(0, 0, 0, 0.3)'}: SwiperProps) => (
  <Grid height={height} width={width} alignItems={'center'} borderRadius={'8px'} border={border}>
    <SwiperLib navigation={true} modules={[Navigation]} className="mySwiper">
      {photoUrls.map((val:any, i: number) => (
        <SwiperSlide key={i}>
          <Hide below="md">
            <Image height={'100%'} objectFit={'cover'} src={val} />
          </Hide>
          <Hide above="md">
            <Image height={'200px'} objectFit={'cover'} src={val} />
          </Hide>
        </SwiperSlide>
      ))} 
    </SwiperLib>
  </Grid>
)