import { extendTheme, theme as chakraTheme } from "@chakra-ui/react";
import "./fontFamily.css";

const fontSizes = {
  xxs: '0.75rem',
  xs: '0.8125rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '2rem',
  '4xl': '2.5rem',
  '5xl': '3rem',
};

const themeGreen = {
  50: '#359794',
  100: '#308885',
  200: '#2A7976',
  300: '#256A67',
  400: '#205B59',
  500: '#1B4B4A',
  600: '#153C3B',
  700: '#102D2C',
  800: '#0B1E1E',
  900: '#050F0F',
};

const colors = {
  ...chakraTheme.colors,
  transparent: 'transparent',
  black: {
    500: '#667085',
    800: '#344054',
    900: '#101828',
  },
  white: '#fff',
  green: themeGreen,
};

const fonts = {
  heading: "AirbnbCereal Bold",
  body: "AirbnbCereal Book",
  bold: "AirbnbCereal Bold",
  medium: "AirbnbCereal Medium",
  light: "AirbnbCereal Light",
  book: "AirbnbCereal Book",
};

const theme = extendTheme({
  fontSizes,
  fonts,
  textStyles: {
    bold: {
      fontFamily: "AirbnbCereal Bold",
      fontWeight: "bold",
    },
    medium: {
      fontFamily: "AirbnbCereal Medium",
      fontWeight: "500",
    },
    light: {
      fontFamily: "AirbnbCereal Light",
      fontWeight: "300",
    },
    book: {
      fontFamily: "AirbnbCereal Book",
      fontWeight: "normal",
    },
  },
  colors,
  styles: {
    global: {
      body: {
        color: colors.black[900],
        fontFamily: "AirbnbCereal Book",
      },
      '.swiper': {
        width: '100%',
        height: '100%'
      },
      
      '.swiper-slide img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      },
      
      '@media (max-width: 971px)':  {
        '.swiper-slide img': {
          height: '200px'
        }
      },

      // '.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer': {
      //   top: '40px'
      // },

      // '.leaflet-container': {
      //   height: '200px'
      // },
      
      // '.apartment-details::-webkit-scrollbar:horizontal, body::-webkit-scrollbar': {
      //   display: 'none'
      // },
      '.apartment-details':{
        overflowX: 'hidden'
      },
      '.apartment-details::-webkit-scrollbar': {
        width: '8px',
      },
      '.apartment-details::-webkit-scrollbar-track': {
        background: "white",
        borderRadius: '8px'
      },
      '.apartment-details::-webkit-scrollbar-thumb': {
        background: 'rgba(247, 247, 247, 1)',
        borderRadius: '8px',
        paddingLeft: '10px'
      },
      '.hide-scrollbar::-webkit-scrollbar, body::-webkit-scrollbar': {
        display: 'none'
      },
      '.chat-box::-webkit-scrollbar, .chats::-webkit-scrollbar': {
        width: '8px',
      },
      '.chat-box::-webkit-scrollbar-track, .chats::-webkit-scrollbar-thumb': {
        background: "white",
        borderRadius: '8px'
      },
      '.chat-box::-webkit-scrollbar-thumb, .chats::-webkit-scrollbar-track': {
        background: 'rgba(247, 247, 247, 1)',
        borderRadius: '8px',
        paddingLeft: '10px'
      },
      '.applications::-webkit-scrollbar, .chats::-webkit-scrollbar': {
        // width: '8px',
        display: 'none',
      },
      '.applications::-webkit-scrollbar-track, .chats::-webkit-scrollbar-thumb': {
        display: 'none',
        // background: "white",
        // borderRadius: '8px'
      },
      '.applications::-webkit-scrollbar-thumb, .chats::-webkit-scrollbar-track': {
        display: 'none',
        // background: 'rgba(247, 247, 247, 1)',
        // borderRadius: '8px',
        // paddingLeft: '10px'
      },
      '.chat.left:before': {
        content: '" "',
        position: 'absolute',
        width: '0',
        height: '0',
        left: '40px',
        right: 'auto',
        top: '0px',
        bottom: 'auto',
        border: '15px solid',
        borderColor: 'rgb(247,247,247) transparent transparent transparent'
      },
      '.chat.right:before': {
        content: '" "',
        position: 'absolute',
        width: '0',
        height: '0',
        left: 'auto',
        right: '0px',
        top: '0px',
        bottom: 'auto',
        border: '15px solid',
        borderColor: `${themeGreen[500]} transparent transparent transparent`
      }
    },
  },
});

// Export as both named and default export
export { theme };
export default theme;
