import { FC, PropsWithChildren, TextareaHTMLAttributes } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { defaultTo } from 'ramda';
const defaultToString = defaultTo('');

export type TextareaFieldProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  name: string;
  label?: string;
  isRequired: boolean;
  chakraProps?: TextareaProps;
  isDisabled?: boolean;
};

export const TextareaField: FC<TextareaFieldProps> = ({
  label,
  isRequired = false,
  isDisabled = false,
  chakraProps = {},
  ...props
}: PropsWithChildren<TextareaFieldProps>) => {
  const [field, { touched, error }] = useField(props);

  return (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      { label? <FormLabel htmlFor={field.name}>{label}</FormLabel>: <></> }
      <InputGroup>
        <Textarea
          disabled={isDisabled}
          {...field}
          {...props}
          {...chakraProps}
          size={'md'}
          id={field.name}
          value={field.value}
        />
      </InputGroup>
      {touched && error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};
