export const SignupCategory = {
  TENANT: {
    value: 'tenant',
    offset: 5
  },
  RENTER: {
    value: 'renter',
    offset: 1
  }
};

// time for which a notification is displayed (in secs)
export const notif_time = 5;

export const apartmentTitles = [
  "Cozy Room in Shared WG",
  "Bright WG Near City Center",
  "Affordable Shared Flat Option",
  "Modern Room in a WG",
  "Spacious WG Close to Transport",
  "Shared Apartment with Great Views",
  "Charming WG in Quiet Area",
  "Friendly Flatshare with Amenities",
  "Stylish Room in Shared Home",
  "Central WG with Big Kitchen",
];
