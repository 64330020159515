import { Flex } from "@chakra-ui/react";

export const DashboardLayout = ({children}: {children: JSX.Element}) => (
  <Flex
    flexDir={'column'} 
    height={`calc(100vh - 61.5px)`} // 61px is the height of navbar on any device
    position={'relative'} //positon relative bcs of the like dislike shortlist btn at bottom
  >
    {children}
  </Flex>
)