import { useRef } from 'react';
import { Flex, Grid, GridItem, Image, Spacer, Box, Icon, IconButton } from '@chakra-ui/react';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { FormikValues, useFormikContext } from 'formik';
import '../swiper.css';

import { Body0, ErrorText, Heading1 } from 'components/molecules';

export const PhotoHighlights = (): JSX.Element => {
  const { setFieldValue, setTouched, errors, touched, values, validateField }: FormikValues =
    useFormikContext();
  let imgInput = useRef<HTMLInputElement | null>(null);

  const fileChange = (e: any) => {
    if (e.target.files.length > 0) {
      const newFiles = Array.from(e.target.files);
      setFieldValue('tenantData.photoHighlights', [
        ...(values.tenantData?.photoHighlights || []),
        ...newFiles,
      ]);
      setTimeout(() => {
        validateField('tenantData.photoHighlights');
        setTouched({ photoHighlights: true });
      }, 1000);
    }
  };

  const removeImage = (index: number) => {
    const updatedPhotos = values.tenantData.photoHighlights.filter((_: any, i: number) => i !== index);
    setFieldValue('tenantData.photoHighlights', updatedPhotos);
  };

  console.log(errors, touched)

  const MAX_IMAGES = 5; // Maximum number of placeholders

  return (
    <>
      <Heading1>Photo Highlights</Heading1>
      <Body0>Add images that show how you are or your personality</Body0>
      <Spacer my={4} flex={0} />
      <Grid
        position={'relative'}
        width={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        borderRadius={'8px'}
        border={'1px dashed rgba(0, 0, 0, 0.3)'}
        borderStyle={'dashed'}
        style={{
          borderImage:
            'repeating-linear-gradient(90deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 15px, transparent 15px, transparent 30px) 1',
        }}
        padding={4}
        gap={4}
        templateColumns="repeat(auto-fit, minmax(100px, 1fr))"
      >
        {Array.from({ length: MAX_IMAGES }).map((_, index) => (
          <GridItem key={index} position="relative">
            {values.tenantData?.photoHighlights?.[index] ? (
              <Box position="relative">
                <Image
                  src={URL.createObjectURL(values.tenantData.photoHighlights[index])}
                  alt={`Thumbnail ${index + 1}`}
                  borderRadius="8px"
                  boxSize="150px"
                  objectFit="cover"
                />
                <IconButton
                  aria-label="Remove Image"
                  icon={<CloseIcon />}
                  size="xs"
                  position="absolute"
                  top="4px"
                  right="4px"
                  backgroundColor="rgba(0,0,0,0.6)"
                  color="white"
                  _hover={{ backgroundColor: 'red.500' }}
                  onClick={() => removeImage(index)}
                />
              </Box>
            ) : (
              <Box
                borderRadius="8px"
                boxSize="150px"
                backgroundColor="gray.200"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={() => imgInput.current?.click()}
              >
                <Icon as={AddIcon} boxSize={6} color="gray.500" />
              </Box>
            )}
          </GridItem>
        ))}
      </Grid>
      <input
        multiple
        ref={imgInput}
        onChange={fileChange}
        style={{ display: 'none' }}
        type="file"
        accept="image/png,image/jpeg,image/jpg"
      />
      {errors.tenantData?.photoHighlights && touched.tenantData?.photoHighlights && (
        <ErrorText>{errors.tenantData.photoHighlights ? `At least add one image` : ''}</ErrorText>
      )}
    </>
  );
};
