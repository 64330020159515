import { Spacer, Text } from "@chakra-ui/react";
import { FormikValues, useFormikContext } from "formik";

import { OptionGroup, Heading1, NextButton, TextareaField } from "components/molecules";
import { useCurrentPage } from "lib";
import { LogoWithName } from "components/organisms";


export const Description = (): JSX.Element => {
  let { options, sideText, fields, nextBtn, setActivePage, activePageIndex } = useCurrentPage();

  let { setFieldValue, values }: FormikValues = useFormikContext();

  const saveField = (items: string[]) => {
    setFieldValue(fields[0], items);
  }

  return(
    <>
      <LogoWithName noName={true} size="70px" />
      <Text fontSize={"2xl"} py={2} textAlign={'center'} textStyle={"bold"}>{sideText}</Text>
      <OptionGroup 
      selected={values.tenantData.descriptions}
      options={options ? options : []}
      onChange={saveField}
      columns={[2, 5]}
      fontSize="sm"
      />
      {nextBtn ? (
        <>
        <Spacer my={2} />
        <TextareaField
          placeholder='Enter a short bio or anything about you..'  
          isRequired={false}
          value={values.tenantData.bio}
          name='tenantData.bio'
        />

        <NextButton onClick={() => setActivePage(activePageIndex+1)}>Next</NextButton>
        </>
      ) : (<></>)}
    </>
  );
}